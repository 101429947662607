<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    right
    width="440px"
    height="90svh"
    temporary
    style="border-radius: 10px 0px 0px 10px; position: fixed"
  >
    <div class="backgroundDrawer">
      <v-row no-gutters align="center" style="margin-bottom: 20px">
        <span class="drawerTitle">Деталі</span>
        <v-row no-gutters align="center" justify="end">
          <div
            class="closeIcon"
            style="cursor: pointer"
            @click="$emit('close')"
          />
        </v-row>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="
          border-bottom: 1px solid #f4f4f4;
          padding: 8px 0px;
          margin-top: 20px;
        "
      >
        <v-col cols="4" class="px-0 py-0">Квиток</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{
            order?.departure?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}
          -
          {{
            order?.destination?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Номер квитка</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          order.id
        }}</v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Відправлення</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{
            new Date(
              addMinutesToDate(
                order?.schedule?.departed_at,
                order?.schedule?.route?.cities?.find(
                  (city) => city.station.city_uuid == order.departure.id
                )?.time_from_start
              )
            ).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          {{
            new Date(
              addMinutesToDate(
                order?.schedule?.departed_at,
                order?.schedule?.route?.cities?.find(
                  (city) => city.station.city_uuid == order.departure.id
                )?.time_from_start
              )
            ).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Прибуття</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{
            new Date(
              addMinutesToDate(
                order?.schedule?.departed_at,
                order?.schedule?.route?.cities?.find(
                  (city) => city.station.city_uuid == order.destination.id
                )?.time_from_start
              )
            ).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          {{
            new Date(
              addMinutesToDate(
                order?.schedule?.departed_at,
                order?.schedule?.route?.cities?.find(
                  (city) => city.station.city_uuid == order.destination.id
                )?.time_from_start
              )
            ).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Дата купівлі</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px"
          v-if="order.paid_at"
          >{{
            new Date(order.paid_at).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          {{
            new Date(order.paid_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</v-col
        >
        <v-col cols="8" class="py-0" style="padding-left: 20px" v-else
          >{{
            new Date(order.created_at).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          {{
            new Date(order.created_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Покупець</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px"
          v-if="order.user !== null"
          >{{ order?.user?.first_name }} {{ order?.user?.last_name }}</v-col
        >
        <v-col v-else cols="8" class="py-0" style="padding-left: 20px"
          >{{ order?.tickets?.[0]?.first_name }}
          {{ order?.tickets?.[0]?.last_name }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Телефон</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px"
          v-if="order.user !== null"
          >{{ order?.user?.phone_number }}</v-col
        >
        <v-col cols="8" class="py-0" style="padding-left: 20px" v-else>{{
          order?.tickets?.[0]?.phone_number
        }}</v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Кількість квитків</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          order.tickets.length
        }}</v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Місце</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">Вільне</v-col>
      </v-row>
      <p class="drawerSubtitle">Оплата</p>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Списані бонусів</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >-{{ order.used_bonus }} {{ order.used_bonus ? "бонусів" : "" }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Промокод</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >-&nbsp;{{ order?.promocode?.amount
          }}{{
            order?.promocode
              ? order?.promocode?.amount_type == "Percent"
                ? "%"
                : "грн"
              : ""
          }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Вартість</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{ order.final_price }} {{ order.final_price ? "грн" : "" }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Статус</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          ><span
            :style="
              order.status.toLowerCase() == 'payed'
                ? 'color: #149E51;'
                : order.status == 'Booking' ||
                  order.status == 'PreBooking' ||
                  order.status == 'PayInBus'
                ? 'color: #E19700'
                : 'color: #DF3A3A;'
            "
          >
            {{
              order.status.toLowerCase() == "payed"
                ? "Оплачено"
                : order.status == "Booking" || order.status == "PreBooking"
                ? "Заброньовано"
                : order.status == "PayInBus"
                ? "Оплата при посадці"
                : "Скасовано"
            }}
          </span></v-col
        >
      </v-row>
      <p class="drawerSubtitle">Автобус</p>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Модель</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{ order.bus.manufacturer }} {{ order.bus.model }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Номер автобуса</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          order.bus.government_number
        }}</v-col>
      </v-row>
      <v-col
        class="px-0 py-0"
        v-for="(ticket, index) in order.tickets"
        :key="ticket.ud"
      >
        <p class="drawerSubtitle">Пасажир {{ index + 1 }}</p>
        <v-row
          no-gutters
          align="center"
          style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
        >
          <v-col cols="4" class="px-0 py-0">ПІБ</v-col>
          <v-col cols="8" class="py-0" style="padding-left: 20px"
            >{{ ticket?.first_name }} {{ ticket?.last_name }}</v-col
          >
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
        >
          <v-col cols="4" class="px-0 py-0">Тип квитка</v-col>
          <v-col cols="8" class="py-0" style="padding-left: 20px">
            {{
              ticket?.discounts?.find(
                (discount) => discount.discount_type == "Social"
              )?.discount?.translations?.title ||
              "Повний" ||
              "Дорослий"
            }}</v-col
          >
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
        >
          <v-col cols="4" class="px-0 py-0">Email</v-col>
          <v-col cols="8" class="py-0" style="padding-left: 20px">{{
            ticket.email
          }}</v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
        >
          <v-col cols="4" class="px-0 py-0">Телефон</v-col>
          <v-col cols="8" class="py-0" style="padding-left: 20px">{{
            ticket.phone_number
          }}</v-col>
        </v-row>
      </v-col>

      <v-col class="px-0 py-0" style="margin-top: 40px">
        <submit-button
          style="width: 400px !important; height: 48px !important"
          @click="downloadTickets"
          >Завантажити квиток</submit-button
        >
        <v-btn class="deleteBtn">Повернення коштів</v-btn>
      </v-col>
    </div>
    <vue-html2pdf
      v-if="order !== null"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`Квиток ${
        order?.departure?.translations.find(
          (translate) => translate.lang == 'ua'
        )?.name
      } - ${
        order?.destination?.translations.find(
          (translate) => translate.lang == 'ua'
        )?.name
      } ${new Date(order?.schedule?.departed_at).toLocaleDateString('uk-UA', {
        month: 'short',
        day: 'numeric',
      })}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          :ticket="order"
          :isPdf="isPdf"
          :text="qrText"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </v-navigation-drawer>
</template>
  
  <script>
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
import submitButton from "../../UI/Buttons/submitButton.vue";
import pdfTicket from "../../User/pdfTicket.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
  mixins: [diffrentTimeMixin],
  components: { submitButton, pdfTicket, VueHtml2pdf },
  data: () => ({
    drawer: true,
    loadingBtn: false,
    qrText: "",
    isPdf: false,
    indexTicket: 0,
  }),
  props: {
    order: {
      require: true,
    },
  },
  methods: {
    downloadTickets(id) {
      this.loadingBtn = true;
      this.qrText = `${location.protocol}//${location.host}/order_qr/${id}`;
      setTimeout(async () => {
        for (const [index] of this.order.tickets.entries()) {
          this.indexTicket = index;
          await this.$refs.html2Pdf.generatePdf();
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.downloadPDF = true;
        }
      }, 500);
      setTimeout(() => {
        this.loadingBtn = false;
      }, 500);
    },
  },
  watch: {
    drawer: {
      handler() {
        if (!this.drawer) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
  
  <style scoped>
.backgroundDrawer {
  border-radius: 10px 0px 0px 10px;
  background: #fff;
  padding: 20px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.drawerSubtitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0px;
}
.drawerTitle {
  font-size: 20px;
  font-weight: 700;
}
.deleteBtn {
  border-radius: 10px;
  background: rgba(223, 58, 58, 0.1) !important;
  height: 46px !important;
  margin-top: 10px;
  width: 400px;
  padding: 12px 16px;
  display: grid;
  place-items: center;
  font-size: 16px !important;
  font-weight: 400;
  color: #df3a3a !important;
  text-transform: none !important;
}
</style>